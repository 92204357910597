<template>
  <div>
    <!------------ START: Variables field ------------>
    <VariablesField
      v-if="isVariablesField"
      v-model="$v.value.$model"
      :field="field"
      :field-wrapper="true"
      :show-toggle="true"
      @toggle-variables-field="toggleVariablesField"
    />
    <!------------ END: Variables field ------------>
    <FieldWrapper
      v-else
      :field="field"
      :input-group="true"
      :variables-field-active="variablesFieldActive"
      @toggle-variables-field="toggleVariablesField"
    >
      <input
        ref="password"
        v-model="$v.value.$model"
        :type="[showPassword ? 'text' : 'password']"
        class="form-control"
        :class="[validationClass]"
        :disabled="isDisabled"
      />
      <div
        v-if="!isVariablesField"
        class="input-group-append"
        @click="showPassword = !showPassword"
      >
        <span class="input-group-text">
          <XentralIcon
            v-if="field.showXentralIcon"
            class="cursor-pointer"
            :icon="passwordIcon"
            color="muted"
          />
          <i
            v-else
            class="fal icon-lg cursor-pointer"
            :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
          />
        </span>
      </div>
    </FieldWrapper>
    <!------------ START: Error messages tooltip ------------>
    <ErrorsTooltip :target="() => $refs.password" :errors="validationErrors" />
    <!------------ END: Error messages tooltip ------------>
  </div>
</template>

<script>
import {
  base,
  eagerValidation,
  variablesField
} from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import ErrorsTooltip from "@/components/Tools/FormHelper/Components/ErrorsTooltip";
import VariablesField from "@/components/Tools/FormHelper/Components/InputVariables";
import XentralIcon from "@/components/Tools/Icons/XentralIcon.vue";

export default {
  components: { XentralIcon, VariablesField, ErrorsTooltip, FieldWrapper },
  mixins: [base, variablesField, eagerValidation],
  props: {},
  data() {
    return {
      showPassword: false
    };
  },
  computed: {
    passwordIcon: function () {
      return this.showPassword ? "hide" : "show";
    }
  },
  mounted() {},
  methods: {}
};
</script>
